// Gatsby supports TypeScript natively!
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Panel from '../components/panel';
import LinkOut from '../components/linkOut';
import TopPanel from '../components/topPanel';
import ButtonLink from '../components/buttonLink';

import HorizontalTitle from '../components/horizontalTitle';
import HorizontalButtonsContainer from '../components/horizontalButtonsContainer';
import VerticalButtonsContainer from '../components/verticalButtonsContainer';
import overview from '../images/overview.png';
import styles from './pageStyles/index.module.css';
import schmidt from '../images/schmidt_futures.png'


const contrastBackgroundColor = 'rgba(65,182,255, 0.25)';
const contrastTextColor = 'black';

const BrowseCellMapsPage = (props: PageProps) => (
  <Layout>
    <SEO title='About' />
   
    <TopPanel>
      <HorizontalTitle title='MuSIC Project' subtitle='About Multi-Scale Integrated Cell Maps' />

      </TopPanel>

    <Panel
    
     >
       <h2>What is the MuSIC project?</h2>
       <p>
       The Multi-scale Integrated Cell (MuSIC) project aims to construct the first systematic map of human cell structure and to establish how such maps will transform our ability to treat disease. Towards these goals, we are developing an end-to-end technology pipeline for mapping cellular architecture which incorporates confocal imaging, mass spectrometry and machine learning as key technologies. Thus far, we have demonstrated the feasibility of this pipeline by mapping a small fraction of the cell, covering approximately 3% of proteins
        (<LinkOut link='https://doi.org/10.1038/s41586-021-04115-9'>Qin et al., Nature 2021</LinkOut>, 
            {' '}
        <LinkOut link='../hek293_paper/' newTab={false}>HEK293 MuSIC Page</LinkOut>).
      
         We have also begun to integrate cell maps in precision diagnosis of cancers (<LinkOut link='https://doi.org/10.1126/science.abf3067'>Zheng et al., Science 2021</LinkOut>). We are currently significantly scaling the platform, as well as conducting basic research into technology alternatives, with the goal of achieving a >10X increase in scale and throughput. The ability to efficiently map cell architecture will set the stage for future efforts to use these maps in pharmaceutical and clinical applications and to comprehensively define the nature of human cells across tissues, diseases, and times during development and aging.


       </p>
     </Panel>
     <Panel
          backgroundColor={contrastBackgroundColor}
          textColor={contrastTextColor}
    >
      <h2>Who is part of the MuSIC project?</h2>
      <p>
     The MuSIC project is led by the <LinkOut link='https://idekerlab.ucsd.edu/'> Ideker Lab</LinkOut> at the University of California San Diego and the {' '}
     <LinkOut link='https://cellprofiling.org'> Lundberg Lab</LinkOut> at Stanford University, and involves many other collaborators. Members of the MuSIC project team can be found on the <LinkOut link='../team/'> Team</LinkOut> page.
      </p>
    </Panel>
     <Panel
    >
      <h2>How are MuSIC Maps made? </h2>
      <p>
      MuSIC integrates data from numerous datasets, some of which are generated in this project and others in sister projects (e.g., <LinkOut link='https://www.proteinatlas.org/'> Human Protein Atlas</LinkOut>, <LinkOut link='https://bioplex.hms.harvard.edu/'> BioPlex Interactome</LinkOut>). Deep neural networks are used to embed each protein in each data modality. For example, node2vec is used to create an embedding for each protein from networks based upon interaction neighborhoods, and Densenet is used to create an embedding for each protein from the imaging data based upon subcellular distributions. Embeddings from the two separate modalities are integrated using either supervised or unsupervised machine learning. Similarities between the integrated embeddings are calibrated to physical distances by calibrating with known subsystems in the Gene Ontology. Pan-resolution community detection is performed to construct a hierarchy of subcellular systems at different scales, from large organelles to small protein complexes. 
     The toolkit to construct MuSIC maps is freely available on <LinkOut link='https://github.com/idekerlab/cellmaps_pipeline?#cm4ai-pipeline'> 
          <strong>GitHub</strong>
        </LinkOut> with usage documentation <LinkOut link='https://cellmaps-pipeline.readthedocs.io/'> 
          <strong>here</strong>
        </LinkOut>
        . 
      </p>
    </Panel>
    <p>
       </p>
       <p>
       </p>
    <div className={styles.logosContainer}>
        <img src={overview} style={{ width: '50vw', minWidth: '10em' }} />
      </div>

      <Panel
          backgroundColor={contrastBackgroundColor}
          textColor={contrastTextColor}
    >
      <h2>How to cite?</h2>
      <p>
      Please cite{' '}
        <LinkOut link='https://www.nature.com/articles/s41586-021-04115-9'>
          Qin et al.</LinkOut> 
           {' ' } Mapping cell structure across scales by fusing protein images and interactions. <i> Nature </i> <strong>600</strong>, 536-542 (2021). 
      </p>
    </Panel>
    <Panel

    >
      <h2>Funding</h2>
      <div className={styles.logosContainer}>
        <div className={styles.logoGroup}>
          <LinkOut link='https://www.schmidtfutures.com/'>
            <img
              src={schmidt}
              alt='Schmidt Futures logo'
              className={styles.logo}
              style={{ maxHeight: '5.5em' }}
            />
          </LinkOut>
        </div>
      </div>
    </Panel>
  </Layout>

);

export default BrowseCellMapsPage;
